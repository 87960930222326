class Utils {
    findClosestAncestor(el, selector) {
        return el.closest(selector);
    }

    fadeOut(element, transistionEndHandler) {
        element.addEventListener('transitionend', transistionEndHandler, true);
        element.classList.add('wl_animation__fadeOut');
    }

    /**
 *
 *
 */
    removeElem(element) {
        element.parentNode && element.parentNode.removeChild(element);
    }

    /**
 *
 *
 *
 */
    registerClickEventListenerFor(elements, listener) {
        elements.forEach(el => {
            el.addEventListener('click', listener);
        });
    }

    isLoggedIn() {
        try {
            return window.o_user.loginState.presenter.isLoggedIn();
        } catch (e) {
            return false;
        }
    }
}

export { Utils };
